import { ActionIcon, Select } from '@mantine/core'

import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import IconTrash from 'src/icons/IconTrash'

const ConditionRow = ({
  label,
  isSubfield = false,
  showDelete,
  handleDelete = () => {},
  ...props
}) => {
  return (
    <div className="flex flex-row items-center gap-2 py-2 text-sm font-medium text-doubleNickel-brand-500">
      <IconCheckmarkCircle
        className={`fill-none stroke-doubleNickel-brand-500 ${
          isSubfield ? 'invisible' : 'visible'
        }`}
      />

      {label}
      <Select
        placeholder="Choose an option"
        classNames={{
          root: 'ml-auto',
          input: 'rounded-lg',
        }}
        {...props}
      />
      <ActionIcon
        variant="subtle"
        className={`${showDelete ? 'visible' : 'invisible'}`}
        onClick={handleDelete}
      >
        <IconTrash className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
      </ActionIcon>
    </div>
  )
}

export default ConditionRow
