import {
  ApplicantStage,
  DisqualifiedReason,
  HiringStage,
  NotInterestedReason,
  TerminatedReason,
} from 'src/graphql/types/applicants'
import { formatSnakeValue } from 'src/lib/formatters'

export const mapApplicantStagetoReason = (conditionValue) => {
  switch (conditionValue) {
    case 'TERMINATED':
      return 'terminatedReason'
    case 'DISQUALIFIED':
      return 'disqualifiedReason'
    case 'NOT_INTERESTED':
      return 'notInterestedReason'
    case 'PROSPECT':
      return 'hiringStage'
  }
}

export const fetchConditionOptions = (condition, jobListings) => {
  const callCountOptions = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20'

  switch (condition) {
    case 'hiringStage': {
      return Object.keys(HiringStage).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'applicantStage': {
      return Object.keys(ApplicantStage).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'notInterestedReason': {
      return Object.keys(NotInterestedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'disqualifiedReason': {
      return Object.keys(DisqualifiedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'terminatedReason': {
      return Object.keys(TerminatedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'callCount': {
      return callCountOptions.split(',').map((option) => ({
        label: option,
        value: option,
      }))
    }
    case 'missedOutboundCallCount': {
      return callCountOptions.split(',').map((option) => ({
        label: option,
        value: option,
      }))
    }
    case 'jobListingId': {
      return jobListings.map((job) => ({
        label: job.title,
        value: job.jobListingId,
      }))
    }
  }
}
