import { useEffect, useState } from 'react'

import { TextInput, Group, Drawer, Divider, Menu } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import { useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  CREATE_AUTOMATED_RULE,
  EDIT_AUTOMATED_RULE,
  GET_AUTOMATED_RULE,
} from 'src/graphql/automatedRules.graphql'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import { GET_TEXT_TEMPLATES } from 'src/graphql/textTemplates.graphql'
import IconAdd from 'src/icons/IconAdd'

import {
  fetchConditionOptions,
  mapApplicantStagetoReason,
} from '../utils/utils'

import ConditionRow from './ConditionRow'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const CONDITIONS_KEYS = [
  {
    label: 'Applicant Stage',
    value: 'applicantStage',
  },
  { label: 'Hiring Stage', value: 'hiringStage' },
  { label: 'Call Count', value: 'callCount' },
  { label: 'Not Interested Reason', value: 'notInterestedReason' },
  { label: 'Disqualified Reason', value: 'disqualifiedReason' },
  { label: 'Terminated Reason', value: 'terminatedReason' },
  { label: 'Missed Outbound Call Count', value: 'missedOutboundCallCount' },
  { label: 'Job Listing', value: 'jobListingId' },
]

const ADD_CONDITION_KEYS = [
  {
    label: 'Applicant Stage',
    value: 'applicantStage',
  },
  { label: 'Call Count', value: 'callCount' },
  { label: 'Job Listing', value: 'jobListingId' },
  { label: 'Missed Outbound Call Count', value: 'missedOutboundCallCount' },
]

const ACTIONS_KEYS = [
  {
    label: 'Send SMS',
    value: 'SMS',
  },
]

const RuleDrawer = ({
  selectedRuleId,
  isOpen,
  refetchQueries,
  onClose,
  isEditing = false,
}) => {
  const [formattedConditions, setFormattedConditions] = useState([])

  // QUERIES:
  const { data } = useQuery(GET_AUTOMATED_RULE, {
    variables: {
      automatedRuleId: selectedRuleId,
    },
    skip: !selectedRuleId,
  })

  const { data: jobListingsResponse } = useQuery(GET_JOB_LISTINGS, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
    },
  })

  const jobListings = jobListingsResponse?.jobListings?.items ?? []
  const selectedRule = data?.automatedRule

  const { data: textTemplatesList } = useQuery(GET_TEXT_TEMPLATES, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
      orderBy: {
        field: 'title',
        direction: 'asc',
      },
    },
  })

  // MUTATIONS:
  const [editRule, { loading: editRuleLoading }] = useMutation(
    EDIT_AUTOMATED_RULE,
    {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('Your rule was updated successfully', 'success')
        onClose()
      },
      onError,
    }
  )

  const [createRule, { loading: createRuleLoading }] = useMutation(
    CREATE_AUTOMATED_RULE,
    {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('A new rule was created successfully', 'success')
        onClose()
      },
      onError,
    }
  )

  //FORM SETUP:
  const schema = z.object({
    name: z
      .string()
      .min(1, { message: 'Please include a title' })
      .max(100, { message: 'Rule title is too long' }),
    conditions: z
      .array(
        z
          .object({
            key: z.string().min(1, { message: 'A condition is required' }),
            value: z
              .string({ invalid_type_error: 'A condition is required' })
              .optional(),
          })
          .refine((data) => {
            //This ensures that only parent conditions are required
            if (
              (data.key === 'applicantStage' || data.key === 'callCount') &&
              data.value === ''
            ) {
              return false
            }
            return true
          })
      )
      .min(1, { message: 'At least one condition is required' }),
    actions: z
      .array(
        z.object({
          actionType: z
            .string({ invalid_type_error: 'An action is required' })
            .min(1, { message: 'An action is required' }),
          textTemplateId: z
            .string({
              required_error: 'A template is required',
              invalid_type_error: 'A template is required',
            })
            .min(1, { message: 'A template is required' }),
        })
      )
      .min(1, { message: 'At least one action is required' }),
  })

  const conditionsList = selectedRule?.conditions
    ? selectedRule.conditions.map((condition) => ({
        key: condition.key,
        value: condition.value,
        conditionId: condition.conditionId,
      }))
    : []

  const actionsList = selectedRule?.actions
    ? selectedRule.actions.map((action) => ({
        actionId: action.actionId,
        actionType: action.actionType,
        textTemplateId: action.textTemplateId,
      }))
    : [{ actionType: '', textTemplateId: '' }]

  const templatesList = textTemplatesList?.textTemplates?.items ?? []

  //This function nests subconditions under their parent conditions and preserves the index from the original list
  const convertConditions = (conditionsList) => {
    const parentConditions = conditionsList.filter(
      (condition) =>
        condition.key === 'applicantStage' ||
        condition.key === 'callCount' ||
        condition.key === 'missedOutboundCallCount' ||
        condition.key === 'jobListingId'
    )
    const subConditions = conditionsList.filter(
      (condition) =>
        condition.key === 'terminatedReason' ||
        condition.key === 'disqualifiedReason' ||
        condition.key === 'notInterestedReason' ||
        condition.key === 'hiringStage'
    )
    const result = parentConditions.map((condition) => {
      const subCondition = subConditions.find(
        (subCondition) =>
          subCondition.key === mapApplicantStagetoReason(condition.value)
      )
      return {
        key: condition.key,
        value: condition.value,
        index: conditionsList.indexOf(condition),
        subCondition: subCondition
          ? {
              key: subCondition.key,
              value: subCondition.value,
              index: conditionsList.indexOf(subCondition),
            }
          : null,
      }
    })
    return result
  }

  const form = useForm({
    initialValues: {
      name: selectedRule?.name || '',
      conditions: conditionsList,
      actions: actionsList,
    },
    validate: zodResolver(schema),
  })

  useEffect(() => {
    form.setValues({
      name: selectedRule?.name || '',
      conditions: conditionsList,
      actions: actionsList,
    })
  }, [selectedRule])

  useEffect(() => {
    setFormattedConditions(convertConditions(form.values.conditions))
  }, [form.values.conditions])

  const onRuleSubmit = (values) => {
    // Filter out any subconditions with empty values
    const filteredConditions = values.conditions.filter((condition) => {
      const subCondition = formattedConditions.find(
        (formattedCondition) =>
          formattedCondition.subCondition?.key === condition.key
      )
      return subCondition ? subCondition.subCondition.value !== '' : true
    })

    const payload = {
      name: values.name,
      conditions: filteredConditions,
      actions: values.actions,
    }

    if (isEditing) {
      editRule({
        variables: {
          automatedRuleId: selectedRule.automatedRuleId,
          input: payload,
        },
      })
    } else {
      createRule({
        variables: {
          input: payload,
        },
      })
    }
  }

  const handleInsert = (condition, value) => {
    //Update the value of the parent condition
    const index = form.values.conditions.findIndex(
      (item) => item.key === condition.key
    )
    form.setFieldValue(`conditions.${index}.value`, value)

    //Remove any existing subcondition
    if (condition.subCondition) {
      const subIndex = form.values.conditions.findIndex(
        (item) => item.key === condition.subCondition.key
      )
      form.removeListItem('conditions', subIndex)
    }
    //Insert a new subcondition
    if (value === 'PROSPECT') {
      form.insertListItem(
        'conditions',
        {
          key: 'hiringStage',
          value: '',
        },
        index + 1
      )
    } else if (value === 'TERMINATED') {
      form.insertListItem(
        'conditions',
        {
          key: 'terminatedReason',
          value: '',
        },
        index + 1
      )
    } else if (value === 'DISQUALIFIED') {
      form.insertListItem(
        'conditions',
        {
          key: 'disqualifiedReason',
          value: '',
        },
        index + 1
      )
    } else if (value === 'NOT_INTERESTED') {
      form.insertListItem(
        'conditions',
        {
          key: 'notInterestedReason',
          value: '',
        },
        index + 1
      )
    }
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title={isEditing ? 'Edit Rule' : 'Create Rule'}
      classNames={{
        title: 'text-lg font-bold',
        root: 'relative overflow-hidden',
      }}
      position="right"
    >
      <form onSubmit={form.onSubmit((values) => onRuleSubmit(values))}>
        <div className="flex flex-col gap-6 overflow-auto">
          <TextInput
            placeholder={'Rule Name'}
            label="Rule Name"
            {...form.getInputProps('name')}
          />
          <Divider />
          <div className="text-sm text-doubleNickel-gray-900">
            Please select an action and when it should be triggered.
          </div>
          <div className="flex flex-col gap-4">
            <ConditionRow
              label="Action"
              isSubfield={false}
              showDelete={false}
              data={ACTIONS_KEYS}
              value={
                form.values.actions.length > 0
                  ? form.values.actions[0].actionType
                  : ''
              }
              onChange={(value) => {
                form.setFieldValue(`actions.${0}.actionType`, value)
              }}
              error={
                form.errors['actions'] || form.errors['actions.0.actionType']
              }
            />
            {form.values.actions.length > 0 &&
              form.values.actions[0].actionType === 'SMS' && (
                <ConditionRow
                  label="Template"
                  isSubfield={true}
                  showDelete={false}
                  value={
                    form.values.actions.length > 0
                      ? form.values.actions[0].textTemplateId
                      : ''
                  }
                  data={templatesList.map((template) => ({
                    label: template.title,
                    value: template.textTemplateId,
                  }))}
                  error={form.errors['actions.0.textTemplateId']}
                  onChange={(value) => {
                    form.setFieldValue(`actions.${0}.textTemplateId`, value)
                  }}
                />
              )}
          </div>

          {formattedConditions.map((condition, index) => (
            <div key={index}>
              <ConditionRow
                key={condition?.conditionId ?? Math.random()}
                label={
                  CONDITIONS_KEYS.find((key) => key.value === condition.key)
                    .label
                }
                isSubfield={false}
                showDelete={true}
                handleDelete={() =>
                  form.removeListItem('conditions', condition.index)
                }
                data={fetchConditionOptions(condition.key, jobListings)}
                value={condition.value}
                error={
                  form.errors[`conditions.${condition.index}`] ||
                  form.errors[`conditions.${condition.index}.value`]
                }
                onChange={(value) => {
                  handleInsert(condition, value)
                }}
              />
              {condition.subCondition && (
                <ConditionRow
                  key={condition?.subCondition?.key}
                  label={
                    CONDITIONS_KEYS.find(
                      (key) => key.value === condition.subCondition.key
                    ).label
                  }
                  isSubfield={true}
                  showDelete={false}
                  data={fetchConditionOptions(condition.subCondition.key)}
                  value={condition.subCondition.value || ''}
                  error={
                    form.errors[
                      `conditions.${condition.subCondition.index}.value`
                    ]
                  }
                  onChange={(value) => {
                    form.setFieldValue(
                      `conditions.${condition.subCondition.index}.value`,
                      value
                    )
                  }}
                />
              )}
            </div>
          ))}

          <div className="flex flex-row items-center justify-between">
            <Menu shadow="md" position="bottom-end">
              <Menu.Target>
                <Button
                  text="Add Condition"
                  variant="light"
                  lefticon={<IconAdd />}
                />
              </Menu.Target>
              <Menu.Dropdown className="w-40">
                {ADD_CONDITION_KEYS.map((condition) => (
                  <Menu.Item
                    key={condition.value}
                    disabled={form.values.conditions.some(
                      (item) => item.key === condition.value
                    )}
                    onClick={() => {
                      form.insertListItem('conditions', {
                        key: condition.value,
                        value: '',
                      })
                    }}
                  >
                    {condition.label}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
            {form.errors['conditions'] && (
              <div className="text-xs text-red-500">
                {form.errors['conditions']}
              </div>
            )}
          </div>
        </div>
        <Group
          justify="flex-end"
          mt="md"
          className="absolute bottom-0 left-0 right-0 mt-auto grid grid-cols-2 gap-4 border-t p-4"
        >
          <Button text="Cancel" variant="outline" onClick={onClose} />
          <Button
            text={isEditing ? 'Save' : 'Create'}
            variant="filled"
            type="submit"
            loading={createRuleLoading || editRuleLoading}
          />
        </Group>
      </form>
    </Drawer>
  )
}

export default RuleDrawer
